// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-atuacao-js": () => import("../src/pages/atuacao.js" /* webpackChunkName: "component---src-pages-atuacao-js" */),
  "component---src-pages-clientes-js": () => import("../src/pages/clientes.js" /* webpackChunkName: "component---src-pages-clientes-js" */),
  "component---src-pages-contato-js": () => import("../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sobre-js": () => import("../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */)
}

